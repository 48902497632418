var m = require("mithril")
// var EvGService = require("../services/EvGService");
var AppState = require("../services/AppState")
var Auth = require("../services/Auth");


class CrewEilandday {

    constructor() {
        //this.list =  [];
        //this.count = 0;
        this.my_creweiland_list = [];
        this.my_creweiland_count = 0;
        this.creweiland_today_list = [];
        this.creweiland_today_count = 0;

        // this.receiveData = this.receiveData.bind(this);
        this.loadMyCrewEilandList = this.loadMyCrewEilandList.bind(this);
        this._receiveMyCreEilandList = this._receiveMyCrewEilandList.bind(this);
        this.loadCrewEilandByDateList = this.loadCrewEilandTodayList.bind(this);
        this._receiveCrewEilandTodayList = this._receiveCrewEilandTodayList.bind(this);
        this._receiveMyCrewEilandList = this._receiveMyCrewEilandList.bind(this);

        this.getCrewEilandByDaytypeTime = this.getCrewEilandByDaytypeTime.bind(this);
    }

    _receiveMyCrewEilandList(result) {
        this.my_creweiland_count = result.count;
        this.my_creweiland_list = result.results;
    }

    loadMyCrewEilandList() {
        const request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/my-crew-eilandday/"+AppState.get_filter_date()+"/"
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveMyCrewEilandList)
    }

    _receiveCrewEilandTodayList(result) {
        this.creweiland_today_count = result.count;
        this.creweiland_today_list = result.results;
    }

    loadCrewEilandTodayList() {
        const request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/crew-eilandday/"+AppState.get_filter_date()+"/"
            // url: Auth.getDomain()+"/api/crew-eilandday/?day="+AppState.get_filter_date()
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveCrewEilandTodayList)
    }

    getCrewEilandByDaytypeTime(day_type_id, time) { // group == day_type
        //let _creweiland = this.creweiland_today_list.forEach( creweiland => {
        //                     if(creweiland.day_type==day_type_id) {
        //                          return creweiland
        //                     }
        //                     })
        return this.creweiland_today_list.filter((creweiland)=>{return creweiland.day_type==day_type_id })
    }


}

module.exports = new CrewEilandday()
